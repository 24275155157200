//default btn styling
.btn {
  border:none;
  font-family:$font-family-webfont;
  white-space: normal;

  @include button-size(11px, 24px, 17px, 1, $border-radius-large);

  line-height:1.3em;

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(.5);
  }
  &.link-more {
    &:after {
      position:relative;
      top:2px;
    }
  }
}

.btn:not(#offcanvas-searchbar .btn) {
  @include isaac-respond-width(xs) {
    font-size: 15px !important;
    width: 100%;
  }
}

//button sizes
.btn-lg {
  @include button-size(12px, 22px, 25px, 1, $border-radius-large);
}

.btn-sm {
  font-family: $font-family-base;
  @include button-size(10px, 10px, 15px, 1, $border-radius-base);
  width:auto !important;

  //bugfix: in Chrome, when icons are used, the small button is stretched
  [class^="icon-"], [class*=" icon-"] {
    line-height:0.9;
  }

}

//button variants
.btn-white {
  @include button-variant($brand-primary, #fff, $brand-primary);
  border-bottom:1px solid rgba(0,0,0,0.7);
  border-right:1px solid rgba(0,0,0,0.7);

}

.btn-dark {
  @include button-variant(#fff, $brand-primary, $brand-primary);
}

.btn-primary, .btn-default, .btn-dark {
  border-bottom:1px solid #9e9e9e;
  border-right:1px solid #9e9e9e;
}

//specific buttons
.btn-toggle-search {
  border-color:transparent;
  border-radius:0;
  font-size:20px;
  padding:10px;
  text-align: center;
  height:36px;
  width:36px;
  color:$brand-primary;
  &:hover,
  &:focus,
  &:active {
    color:$brand-primary;
    border-color:transparent;
    @include box-shadow(none);
  }

  &:hover {
    color:$gray;
  }


  &.collapsed,
  &.collapsed:hover,
  &.collapsed:focus,
  &.collapsed:active  {
    color:$gray;
    @include box-shadow(none);
  }

  &.collapsed:hover {
    color:$brand-primary;
  }
}

.btn-offcanvas-close {
  border-width:0;
  height:36px;
  width:36px;
  padding:8px 0;
  text-align: center;
  .icon-cross {
    font-size:22px;
  }

  &:hover {
    background-color: darken($brand-primary, 5%);
  }
}

.btn-jumbotron {
  @include isaac-respond-width(xs) {
    background-color: $brand-secondary;
    color: #fff;
    border-bottom: 1px solid #9e9e9e;
    border-right: 1px solid #9e9e9e;
    width: 100%;
  }
}
$apply-wizard-nav-height: 42px!default;
$apply-wizard-nav-bg: $gray-lighter!default;
$apply-wizard-nav-color: inherit!default;
$apply-wizard-nav-active-bg: $gray!default;
$apply-wizard-nav-active-color: #fff!default;
$apply-wizard-nav-completed-bg: $brand-primary!default;
$apply-wizard-nav-completed-color: #fff!default;
$apply-wizard-nav-border-radius: $border-radius-base!default;
$apply-tipsy-bg: $gray-light!default;
$apply-tipsy-color: #fff!default;
$apply-aside-border-radius: $panel-border-radius!default;
$apply-aside-bg:$gray-lighter!default;
$apply-aside-arrow-color:$gray!default;
.extra-service-block {
    border-bottom: 1px solid $gray-light;
    padding:30px 0 30px;
    padding:20px 0;

    @include isaac-respond-min-width(sm){
        padding:30px 0;
    }

    h2 {
        margin-top:0;
    }
    .extra-service-block-image {
        margin-top:7px;
        margin-bottom:15px;
        max-width: 130px;
        @include isaac-respond-min-width(sm){
            margin-bottom:0;
        }
    }
}
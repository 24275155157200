header {

  $sticky-header-height-xs: 77px;
  $sticky-header-height-md: 110px;

  .afm {
    background: url(img/afm.svg) no-repeat center center;
    background-size: 380px;
    text-indent: -99999px;
    height: 100px;
    border-bottom: 1px solid #000;
    @include isaac-respond-width(xs) {
      background-size: 260px;
      height: 70px;
    }
  }

  #header-sticky {
    border-bottom:1px solid $brand-primary;
    position:static; //.affix is added by affix plugin, but should be ignored on for smaller viewports
    height: $sticky-header-height-xs;
    @include isaac-respond-min-width(md) {
      height: $sticky-header-height-md;
    }

  }

  @include isaac-respond-min-width(md) {
    //reserve space for the sticky header and position the header on top of it
    margin-bottom: $sticky-header-height-md;
    #header-sticky {
      position:absolute;
      width: 100%;
      z-index: $zindex-dropdown - 1;
      background-color: #fff;
      //.affix class is added by the Bootstrap affix plugin
      &.affix {
        position:fixed;
        top:0;
      }
    }
  }

  .offcanvas-toggle {
    margin:18px 0 0;

    @include isaac-respond-min-width(md) {
      margin:32px 9px 0 0;
    }

    .offcanvas-toggle-bars {
      top:-1px;
      // Bars
      .bar {
        display: block;
        width: 19px;
        height: 3px;
        background-color:#fff;
      }
      .bar + .bar {
        margin-top: 3px;
      }
    }

    .offcanvas-toggle-label {
      padding-left:4px;
    }

  }

  //hide offcanvas toggle and sho close offcanvas button on sm only when offcanvas is open
  .btn-offcanvas-close {
    display: none;
  }

  @include isaac-respond-width(xs) {
    .offcanvas-open & {
      .offcanvas-toggle {
        display: none;
      }
      .offcanvas-toggle.btn-offcanvas-close {
        display: block;
      }
    }
  }

  .btn-toggle-search {
    margin:10px 0 0 0;
  }

  .logo {
    margin:18px 0 0 0;

    //specify image width only, so it is known before the image is loaded
    //image height is auto
    > a > img {
      width:170px;
    }

    @include isaac-respond-min-width(md) {
      margin:10px 25px 0 0;
    }

  }


  #searchbar {
    position:absolute;
    width:100%;
    background-color:$brand-primary;
    z-index:$zindex-dropdown - 1;
    padding:14px 0;
    margin-top: 16px;
  }
}


// website without offcanvas menu is contained within #content
#content {
  background-color: $content-bg;
  -webkit-box-shadow: 0 0 10px -2px rgba(0,0,0,0.5);
  box-shadow: 0 0 10px -2px rgba(0,0,0,0.5);
}

.row-padding-bottom {
  padding-bottom:30px;
}

main {
  padding-bottom:40px;

  //if last item is a jumbotron, remove the padding
  > :last-child.jumbotron,
  > :last-child.strip-quicklinks{
    margin-bottom:-40px;
  }
}


//rows with padding
.row-padding-bottom-xs {
  padding-bottom:10px;
}

.row-padding-top-xs {
  padding-top:10px;
}

.row-padding-bottom-sm {
  padding-bottom:20px;
}

.row-padding-top-sm {
  padding-top:20px;
}

.col-no-padding {
  padding-left:0;
  padding-right:0;
}
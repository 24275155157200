//form controls
.form-control {
    font-size: 16px; //prevent iPhone from zooming in when clicking an input that has a font-szie smaller then 16px

    @include box-shadow(inset 3px 3px 6px rgba(0, 0, 0, .075));
    @include isaac-respond-min-width(md) {
        &.form-control-25 {
            width: 25%;
        }

        &.form-control-50 {
            width: 50%;
        }

        &.form-control-75 {
            width: 75%;
        }
    }

    &.error {
        border: 1px solid $state-danger-border;
    }

}

.control-label {
    font-family: $headings-font-family;
    font-size: 16px;
    font-weight: bold;
    color: $brand-primary;
    flex-direction: row !important;
    .asterix {
        display: none;
    }
}

//when form-control are added by js, they cannot always have a .form-control class
// by wrapping these dynamically created controls in a .form-control-wrapper, we can add the styling
.form-control-wrapper {
    input, select {
        @extend .form-control;
    }
}

label {
    font-weight: normal;
}

select.select-wide {
    width: 100%;
}

input[type=number] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

#genericFormResetButton {
    display: none;
}

.form-horizontal {
    @media (min-width: $screen-sm-min) {
        .control-label {
            text-align: left;
        }
    }
}

.help-block {
    color: $gray;
}

//form errors

.form-group.has-error { //Bootstrap default uses .has-error
    .help-block, //Bootstrap makes all these element red if the form-group is in error state
    .control-label {
        color: inherit;
    }
    //errors, triggered by javascript validation
    label.error {
        @extend .help-block; //inherit margin from help-block
        font-weight: normal;
        color: $state-danger-text;
    }

    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        label.error {
            display: none !important;
        }
    }
}

.form-group.error { //Aanvraagstraat uses .error
    .form-control {
        border-color: $state-danger-border !important;
    }
}

label.error {
    @extend .help-block; //inherit margin from help-block
    font-weight: normal;
    color: $state-danger-text;
}

//remove IE clear field X button
::-ms-clear {
    display: none;
}

.form-control-wrapper-euro {
    position: relative;
    &:after {
        content: '\20AC'; //euro sign
        display: inline-block;
        position: absolute;
        top: 6px;
        left: 9px;
        pointer-events: none;
        color: $input-color;
        font-size: 16px;
    }
    .form-control {
        padding-left: 24px;
    }
}

.form-group-no-margin {
    margin-bottom: 0;
}

.form-group-margin-top {
    margin-bottom: 0;
    margin-top: 15px;
}

.form-apply-settings {
    padding: 15px;
    background-color: $panel-bg;
    border-top: 1px solid #ACC6D1;

    div[class^="col-"],
    div[class*=" col-"] {
        padding-right: 30px;
    }
}

.form-searchbar {

    .form-group {
        margin-bottom: 0;
    }

    input.form-control {
        padding-right: 50px;
    }

    //position search button over input field
    .btn {
        color: $gray;
        position: absolute;
        top: 1px;
        right: 10px;
        background-color: transparent;
        border-width: 0;

        &:hover, &:focus, &:active {
            @include box-shadow(none);
            outline: none;
            color: $brand-primary;
        }

        &:active {
            color: $brand-tertiary;
        }

        .icon-search {
            font-size: 28px;
            position: relative;
            top: -1px;
        }
    }
}

.calculator-multiple-section-temp, .calculator-section-dark {
    #SpendingPurpose-error, #futureWithdrawals-error {
        display: none;
    }
    #SpendingPurposeCalc {
        width: auto;
        max-width: 90%
    }
    label.goal {
        line-height: 36px;
    }
    label.futureWithdrawals {
        display: inline;
    }
}

section {
    padding-bottom:30px;
    &.section-no-padding-bottom {
      padding-bottom:0;
    }
}

.section-dark {
  background-color: $brand-primary;
  color:#fff;
  h1, h2, h3, h4, h5, h6 {
    color:#fff;
  }
  a:not(.btn) {
    color:#fff;
    text-decoration: underline;
  }
  ul > li:before {
    color:#fff;
  }
}

.content-wysiwyg {
  .apply-done-icon-list {
    list-style: none;
    padding:0;
    margin: 15px 0 0 0;

    > li {
      position: relative;
      margin: 0 0 20px 0;
      padding:22px 15px 15px 45px;
      counter-increment: apply-done-icon-list-counter;
      background-color: $gray-lighter;
      border-radius:$panel-border-radius;

      @include isaac-respond-min-width(sm) {
        padding:20px 135px 15px 80px;
      }

      h3 {
        margin-top: 0;
        padding-right:25px;
        @include isaac-respond-min-width(sm) {
          padding-right:0;
        }
      }


      //add steps identifier
      &:before {
        content: counter(apply-done-icon-list-counter);
        display:block;
        position:absolute;
        width:24px;
        height:24px;
        line-height: 24px;
        border-radius: 50%;
        background-color: $brand-primary;
        font-size:15px;
        text-align: center;
        color:#fff;
        position:absolute;
        font-family: $headings-font-family;
        left:10px;
        top:22px;
        padding:0;

        @include isaac-respond-min-width(sm) {
          width:36px;
          height:36px;
          line-height: 36px;
          left:23px;
          top:18px;
          font-size:18px;
          left:23px;
        }

      }

      //add arrows
      &:after
      {
        content: '';
        display:block;
        position: absolute;
        bottom:-29px;
        width:0;
        height: 0;
        border-width: 15px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        left:50%;
        margin-left:-7px;

      }

      .fa {
        width:32px;
        height:32px;
        font-size:24px;
        text-align: center;
        padding-top:10px;
        color:$brand-primary;
        position:absolute;
        right:5px;
        top:10px;
        @include isaac-respond-min-width(sm) {
          width:48px;
          height:48px;
          font-size:36px;
          padding-top:10px;
          right:45px;
          top:45px;
        }
      }

      &:last-child {
        //no arrow on last child
        &:after {
          display: none;
        }
      }
    }
  }

}

//override default apply variables
$apply-wizard-nav-bg: $gray-lighter;
$apply-wizard-nav-active-bg: $brand-primary;
$apply-wizard-nav-active-color: #fff;
$apply-wizard-nav-completed-bg: $gray-lighter;
$apply-wizard-nav-completed-color: $brand-primary;
$apply-tipsy-bg: $gray;
$apply-aside-arrow-color:$brand-spotcolor-dark;


//default apply sass
@import "../../../algemeen/src/apply/sass/variables";
@import "../../../algemeen/src/apply/sass/apply-base";
@import "../../../algemeen/src/apply/sass/apply-icons";
@import "../../../algemeen/src/apply/sass/apply-tooltip";
@import "../../../algemeen/src/apply/sass/apply-aside";


//brand specific overrides
@import "apply-base";
/**
* ------------------------------------------------------------------------------------------------- #
*  Jquery UI Slider
*/

.input-wrapper {
  position:relative;
  margin-bottom:10px;

  @include isaac-respond-min-width(sm) {
    margin-bottom:0;
  }

  input[type="text"],
  input[type="number"],
  input[type="tel"]{
    border:1px solid $input-border;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-radius:$input-border-radius;
    width:100%;
    height:25px;
    padding-left:18px;
    padding-top:0;
    padding-bottom:0;
    lin-height:25px;
    text-align: left;
    font-size:16px;
  }


  &:after {
    content:'\20AC'; //euro sign
    display:inline-block;
    position:absolute;
    top:0;
    height:25px;
    line-height: 25px;
    left:7px;
    pointer-events: none;
  }
  &.input-wrapper-lg {
    margin-bottom:0;

    input.slideramount {
      color:$brand-primary;
      background:$brand-spotcolor-light;
      border:1px solid $brand-spotcolor-dark;
      border-radius:5px;
      height:52px;
      line-height:52px;
      font-size:20px;
      text-align: center;
      padding-left:0;
    }
    &:after {
      font-size:20px;
      line-height:52px;
      top:0;
      left:50%;
      margin-left:-55px;
      color:$brand-primary;
    }
  }

  &.input-wrapper-percent {
    &:after {
      content: '%';
      left:auto;
      right:4px;
    }
  }

  &.input-wrapper-empty {
    &:after {
      content:'';
    }
  }

}




.slider-wrapper {
  padding:7px 0 5px;
  transition: none!important;

  &.slider-wrapper-lg {
    padding:29px 0 42px;
  }
}




.ui-slider { position: relative; text-align: left;}
.ui-slider .ui-slider-handle { position: absolute; z-index: 2; width: 1.2em; height: 1.2em; cursor: default; }
.ui-slider .ui-slider-range { position: absolute; z-index: 1; font-size: .7em; display: block; border: 0; }
.ui-slider-horizontal { height: 0.8em; }

.ui-slider-horizontal .ui-slider-range { top: 0; height: 100%; }
.ui-slider-horizontal .ui-slider-range-min { left: 0; }
.ui-slider-horizontal .ui-slider-range-max { right: 0; }

//slider background
.ui-slider.ui-widget-content {
  border:1px solid #d1d7e0;
  border-radius:3px;
  background:#fff;
  height:13px;
  //keep slider away from sides, to make it easier to grab the handle
  margin:0 20px;
  @include isaac-respond-min-width(sm) {
    margin:0 0;
  }
}

//left of slider handle
.ui-slider-range ,.ui-slider-range-min {
  background:#d1d7e0;
  color: #FFFFFF;
}

//slider handle
.ui-slider-handle.ui-state-default {
  background:$brand-primary;
  width:36px;
  height:25px;
  border:0;
  margin: -8px 0 0 -17px;
  outline: none;
  border:0;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.48);
  -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.48);
  box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.48);
  text-decoration: none;

  &:before,&:after {
    font-family: 'icomoon';
    color:#fff;
    font-size:20px;
    line-height:25px;
    position:absolute;
  }
  &:before {
    content: "\e60d"; //angle left
    left:8px;
  }
  &:after {
    content: "\e60c"; //angle right
    right:8px;
  }

}
@font-face {
  font-family: 'icomoon';
  src:url('/global/fonts/icomoon/icomoon.eot?-9wonfi');
  src:url('/global/fonts/icomoon/icomoon.eot?#iefix-9wonfi') format('embedded-opentype'),
  url('/global/fonts/icomoon/icomoon.ttf?-9wonfi') format('truetype'),
  url('/global/fonts/icomoon/icomoon.woff?-9wonfi') format('woff'),
  url('/global/fonts/icomoon/icomoon.svg?-9wonfi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


//icons with blue circle
.icon-circle-md,
.icon-circle-lg {
  position:relative;
  display:inline-block;
  background-color: $brand-primary;
  border-radius: 50%;
  text-align: center;
  color:#fff;
}

.icon-circle-md {
  width: 36px;
  height: 36px;
  color:#fff;
  font-size:20px;
  padding-top:7px;
}

.icon-circle-lg {
  width: 80px;
  height: 80px;
  font-size:40px;
  padding-top:20px;
}

//positioning
.icon-angle-right {
  position:relative;
  top:2px;
}

//icons content
.icon-minus:before {
  content: "\e619";
}
.icon-plus:before {
  content: "\e61a";
}
.icon-linkedin:before {
  content: "\e618";
}
.icon-twitter:before {
  content: "\e617";
}
.icon-facebook:before {
  content: "\e616";
}
.icon-user:before {
  content: "\e602";
}
.icon-search:before {
  content: "\e606";
}
.icon-home:before {
  content: "\e600";
}
.icon-exclamation-circle:before {
  content: "\e615";
}
.icon-exclamation-triangle:before {
  content: "\e614";
}
.icon-download:before {
  content: "\e613";
}
.icon-cross:before {
  content: "\e612";
}
.icon-info-outline:before {
  content: "\e611";
}
.icon-circle-plus:before {
  content: "\e610";
}
.icon-circle-minus:before {
  content: "\e60f";
}
.icon-check:before {
  content: "\e609";
}
.icon-automobile:before {
  content: "\e60e";
}
.icon-angle-down:before {
  content: "\e60a";
}
.icon-angle-up:before {
  content: "\e60b";
}
.icon-angle-right:before {
  content: "\e60c";
}
.icon-angle-left:before {
  content: "\e60d";
}
.icon-star-half:before {
  content: "\e607";
}
.icon-star:before {
  content: "\e608";
}
.icon-callback:before {
  content: "\e601";
}
.icon-documents:before {
  content: "\e605";
}
.icon-envelope:before {
  content: "\e603";
}
.icon-phone:before {
  content: "\e604";
}
.icon-bars:before {
  content: "\e61b";
}

.calc_error_main {
  position: absolute;
  background-color: $alert-danger-bg;
  color:$alert-danger-text;
  max-width: 300px;
  padding:10px 15px;
  border: 1px solid red;
  z-index:$zindex-dropdown - 2;
  border-radius:$alert-border-radius;
}

#bespaarcal {
  position:relative;
}

.calculator {
    position: relative;
    .notification-withdrawals {
        margin: -20px;
        position: absolute;
        display: none;
        background: $brand-spotcolor-light;
        opacity: 0.95;
        width: 100%;
        height: 100%;
        z-index: 200;
        color: #000;
        text-align: left;
        padding: 20px;

        .btn-primary {
            font-size: 22px;
            margin-top: 18px;
        }
    }
}

.calculatortableheader {
  color:$brand-secondary;
}

.calculator-section-margin-bottom {
  margin-bottom:40px;
}

.calculator-section-dark {
  background-color: $brand-sectioncolor-light;
  padding: 20px;
}

.calculator {
  border-top:1px solid $brand-spotcolor-dark;
  border-bottom:1px solid $brand-spotcolor-dark;
  background-color: $brand-sectioncolor-light;
  padding: 20px;
  margin-bottom: 20px;

  &.calculator-equal-height {
    @include isaac-respond-min-width(sm) {
      min-height: 425px;
    }
  }

  .calculator-section-dark + & {
    border-top-color: transparent;
    padding-top:0;
  }

  .tooltip {
    padding-right:2px;
  }

  .calculator-form {
    text-align: center;
  }

  .calculator-notavailable {
    display: none;
    position:relative;
  }

  .calculator-intro {
    h2 {
      margin-top:0;
    }
  }

  .calculator-userselection {
    border-bottom:1px solid $brand-spotcolor-dark;
    padding: 0 0 20px 0;
    position: relative;
    &:after {
      content: ' ';
      display:block;
      position: absolute;
      background-color: $brand-sectioncolor-light;
      border-left: 1px solid $brand-spotcolor-dark;
      border-bottom: 1px solid $brand-spotcolor-dark;
      transform: rotate(-45deg);
      width:20px;
      height:20px;
      left:50%;
      bottom:-11px;
      margin-left:-10px;
    }

    //primeline input elements have a gray background, but the disabled state also has a gray background
    //give the input elements another background color to fix this
    .form-control:not([disabled]) {
      background-color: #fff;
    }
  }

  .calculator-monthterm {
    text-align: center;
    margin:0 20px;
    min-height: 100px;

    .calculator-amount {
      font-size: 42px;
      color: $brand-secondary;
    }
  }

  .calculator-months {
    padding-top:0!important;
    padding-bottom:0!important;
    select.form-control {
      padding-left:2px;
      padding-right:0;
    }
  }


  .calculator-aflossing-options > input {
    position:absolute;
    visibility: hidden;


    + label {
      display:inline-block;
      background-color: #fff;
      padding:6px 12px;
      margin:0;
      border-radius: 3px;
      margin-right: 10px;
      min-width: 70px;
      text-align: center;
      border:1px solid #ccc;
      @include user-select(none);
      cursor: pointer;
    }

    &[disabled] {
      + label {
        background-color: $input-bg-disabled;
      }
    }

    &:checked:not([disabled]) {
      + label {
        background-color: $brand-primary;
        border-color:$brand-primary;
        color:#fff;
        box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.48);
        font-weight: bold;
      }
    }

  }

  .calculator-button {
    display:none;
    margin-top: 20px;
    > .btn-default {
      //.btn-primary has an icon in it, which makes the button higher then the .btn-scondary. Fix this:
      padding-top:13px;
      padding-bottom:13px;
    }
    > .btn {
      font-size: 20px;
      @include isaac-respond-min-width(sm) {
        font-size: 22px;
      }
    }
  }


  .calculator-summary {
    margin-top: 20px;
    table {
      width: 100%;
      td {
        padding: 6px 0;
        vertical-align: top;
        &:last-child {
          text-align: right;
        }
      }
    }

    &.disabled {
      opacity: 0.3;
    }


  }

}

.calculator-hmcib-result {
  text-align: center;
  padding:20px 0;
  .btn {
    margin-top:10px;
  }
}
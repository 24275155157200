footer {

  padding:30px 0 52px 0;
  background-color: #ebebeb;

  a {
    color: $text-color;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $brand-primary;
      text-decoration: underline;
    }
  }


  .footer-nav {
    margin-bottom:10px;
    @include isaac-respond-min-width(md) {
      margin-bottom:20px;
    }

    h3 {
      font-family: $font-family-base;
      font-size:14px;
      color:$brand-primary;
      margin:22px 0 18px 0;
    }

    ul {
      @extend .list-unstyled;
      > li {
        padding-bottom:14px;
      }
    }
  }

  hr {
    margin: 22px 0 0 0;
  }

  .footer-images {
    @include isaac-respond-min-width(sm) {
      text-align: center;
    }
    @include isaac-respond-min-width(md) {
      text-align: left;
      margin-top:9px;
    }
    a.vfn img {
      width:38px;
      height:38px;
    }
    a.privacy-waarborg img {
      width:76px;
      height:38px;
      margin-left:10px;
    }
  }


  .footer-menu-content {
    margin-top:22px;
    padding-top:30px;
    font-size:14px;

    @include isaac-respond-min-width(md) {
      padding:8px 0 0 0; //align links with social media icons, which are larger
    }

    @include isaac-respond-min-width(sm) {
      text-align: center;
    }


    ul {
      list-style: none;
      display:inline-block;
      padding:0;

      > li {
        margin-bottom:9px;
        display: block;

        @include isaac-respond-min-width(sm) {
          display:inline-block;
        }

        //add list seperator
        @include isaac-respond-min-width(sm) {
          &:after {
            content: '|';
            position:relative;
            margin-left:12px;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }

    //on sm, the list is a bit too large, so makeit a bit smaller
    @include isaac-respond-width(md) {
      font-size:13px;
    }

  }

}




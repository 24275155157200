.jumbotron-link {
    text-decoration: none !important;
    &:hover, &:active {
        text-decoration: none !important;
    }
}
.jumbotron {
    background-color: $brand-primary;
    background-repeat: no-repeat;
    background-size: auto 140px;
    min-height: 140px;
    background-position: 80% 0;
    margin-bottom: 20px;

    @media only screen and (min-width: 656px) {
        background-size: auto 164px;
        min-height: 164px;
    }

    @include isaac-respond-min-width(sm) {
        background-position: top center;
        height: 360px;
        min-height: 0;
        overflow: hidden;
        background-size: cover;
    }

    @include isaac-respond-min-width(md) {
        height: 410px;
    }

    .jumbotron-body {
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        padding-top: 140px;
        padding-bottom: 20px;

        @media only screen and (min-width: 656px) {
            padding-top: 164px;
        }

        @include isaac-respond-min-width(sm) {
            padding-top: 0;
            color: inherit;
        }

        .jumbotron-heading,
        .jumbotron-heading-sub {
            color: #fff;
            display: block;
            font-family: $font-family-extrabold;
            font-weight: normal;
            line-height: 1.2;
            margin-top: 20px;
            font-size: 22px;

            @include isaac-respond-min-width(sm) {
                margin-top: 30px;
                color: $brand-primary;
                font-size: 34px;
                padding-top: 0;
            }

            @include isaac-respond-min-width(md) {
                margin-top: 30px;
                margin-bottom: 10px;
            }
            @include isaac-respond-min-width(lg) {
                font-size: 40px;
                margin-top: 50px;
                margin-bottom: 20px;
            }
        }

        .btn {
            margin-top: 20px;
        }

        @include isaac-respond-max-width(xs) {
            a:not(.btn) {
                color: #fff;
                text-decoration: underline;
            }
        }
    }

    //color variants
    &.jumbotron-highlight {
        background-color: $brand-secondary;
        height: auto;
        .jumbotron-body {
            color: $brand-primary;
            font-size: inherit;
            font-weight: normal;
            .jumbotron-heading,
            .jumbotron-heading-sub {
                font-family: $font-family-base;
                font-weight: bold;
                color: $brand-primary;
                font-size:24px;
            }
            .btn-white {
                border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                border-right: 1px solid rgba(0, 0, 0, 0.4);
            }
        }
    }

    &.jumbotron-noimg {
        &:not(.jumbotron-highlight) {
            .jumbotron-body {
                color: #fff;
            }
        }

        .jumbotron-body {
            padding-top: 0;
            .jumbotron-heading,
            .jumbotron-heading-sub {
                color: #fff;
            }
        }
    }

    .jumbotron-image {
        margin: 10px auto 0;
        text-align: center;
        width: 150px;
        @include isaac-respond-min-width(sm) {
            margin: 50px auto;
        }
        @include isaac-respond-min-width(md) {
            width: 175px;
        }
        @include isaac-respond-min-width(lg) {
            width: 236px;
        }
        img {
            width: 100%;
        }
    }

}

.jumbotron-homepage {
    margin-top: 0;
    margin-bottom: 22px;
    border-radius: $border-radius-large;
    text-align: center;
    min-height: 315px;
    background-size: contain;
    background-position: bottom center;

    .jumbotron-body {
        padding: 10px 40px 20px;
        h1, h2, h3, h4, h5, h6 {
            &.jumbotron-heading {
                color: #fff;
            }
            &.jumbotron-heading-sub {
                color: $brand-secondary;
            }
            &.jumbotron-heading,
            &.jumbotron-heading-sub {
                font-size: 24px;
                margin-top: 10px;
            }
        }
    }

    @include isaac-respond-min-width(sm) {
        height: 339px;
        .jumbotron-body {
            .jumbotron-heading,
            .jumbotron-heading-sub {
                margin-top: 30px;
            }
        }
    }

    @include isaac-respond-min-width(md) {
        height: 339px;
    }

}
.apply-aside {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  -ms-flex-negative: 0; /* <- IE 11 flex height */

  @media only screen and (min-width: 450px) and (max-width: $screen-xs-max) {
    flex-direction: row;
  }

  .apply-panel-product {
    background-color: $apply-aside-bg;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    order: 1;
    border-radius: $panel-border-radius;
    -ms-flex-negative: 0; /* <- IE 11 flex height */

    @media only screen and (min-width: 450px) and (max-width: $screen-xs-max) {
      margin-top: 20px;
      width: 50%;
      order: 0;
    }

    @include isaac-respond-min-width(sm) {
      order: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 20px;

      li {
        margin-bottom: 5px;
        padding-left: 23px;
        position: relative;
        font-size: 14px;

        @include isaac-respond-width(sm) {
          font-size: 13px;
        }

        &:before {
          @include fa-icon();
          content: $fa-var-check-circle;
          color: #3bb54a;
          position: absolute;
          left: 0px;
          top: 4px;
          font-size: 16px;
        }
      }
    }

    table {
      margin-top: 20px;

      td,
      th {
        font-size: 14px;
        border-top: 0;
        padding-bottom: 10px;
        vertical-align: top;
      }

      th {
        padding-left: 5px;
        text-align: right;
      }

      td:first-child,
      td:first-child {
        padding-left: 0;
      }
      th:first-child,
      th:first-child {
        padding-right: 0;
      }
    }

    .apply-panel-product-arrow {
      border-bottom: 1px solid $apply-aside-arrow-color;
      padding: 0 0 20px 0;
      margin: -5px 0 20px 0;
      position: relative;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        background-color: $apply-aside-bg;
        border-left: 1px solid $apply-aside-arrow-color;
        border-bottom: 1px solid $apply-aside-arrow-color;
        transform: rotate(-45deg);
        width: 20px;
        height: 20px;
        left: 50%;
        bottom: -11px;
        margin-left: -10px;
      }
    }
  }

  .apply-panel-tips {
    padding: 15px;
    order: 0;

    //Dotcms Edit contentlet button
    .dotEditListBox {
      position: relative;
      z-index: 999999;
      position: absolute;
      right: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }

    @media only screen and (min-width: 450px) and (max-width: $screen-xs-max) {
      margin-top: 20px;
      width: 50%;
      order: 1;
    }

    @include isaac-respond-min-width(sm) {
      order: 1;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 15px;
        padding-left: 35px;
        position: relative;
        font-size: 14px;

        @include isaac-respond-width(sm) {
          font-size: 13px;
        }

        a {
          color: inherit;
          text-decoration: underline;
          &:active,
          &:hover {
            color: $link-color;
          }
        }

        .fa {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background-color: $brand-primary;
          font-size: 16px;
          text-align: center;
          padding-top: 5px;
          color: #fff;
          position: absolute;
          left: 0;
          top: -2px;
        }
      }
    }
  }
}

.apply-openingshours {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  min-height: 27px;

  @include isaac-respond-width(sm) {
    font-size: 13px;
  }

  .apply-openingshours-toggle {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
      @include fa-icon();
      content: $fa-var-chevron-down;
      position: absolute;
      right: -15px;
      top: 6px;
      font-size: 11px;
      opacity: 0.7;
    }
  }

  .apply-openingshours-today {
    font-weight: bold;
  }

  .fa {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: $brand-primary;
    font-size: 16px;
    text-align: center;
    padding-top: 5px;
    color: #fff;
    position: absolute;
    left: 0;
    top: -2px;
  }

  .apply-openingshours-table {
    opacity: 1;
    transition: opacity 0.3s;
    tr {
      td:first-child {
        text-align: left;
        padding-right: 10px;
      }
      td {
        text-align: right;
        padding-bottom: 4px;
      }
    }
  }

  &.collapsed {
    .apply-openingshours-toggle {
      opacity: 1;
    }
    .apply-openingshours-table {
      opacity: 0;
    }
  }
}

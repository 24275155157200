.tipsy-tooltip, .modal-tooltip {
  margin:0;
  padding:0;
  cursor:default;
  text-decoration: none!important; //for IE
  &:after {
    display:inline-block;
    border:1px solid $brand-primary;
    color:$brand-primary;
    text-align: center;
    line-height:21px;
    width:21px;
    height:21px;
    border-radius:50%;
    font-size:15px;
    font-weight:bold;
    line-height:1;
    padding-top:2px;
    content: "i";
  }
}

.modal-tooltip {
  margin-left:5px;
}

.validicon {
  &:before {
    @include fa-icon();
    content: $fa-var-check;
    display:block;
    color:#3bb54a;
    font-size:20px;
  }
}
.error.tipsy {
  background-image: none;
  padding:0;
  text-decoration: none;
  display:block;
  float:right;
  &:before {
    @include fa-icon();
    content: $fa-var-exclamation-circle;
    color:$state-danger-border;
    font-size:22px;
  }
}

.loading{
  &:before {
    @include fa-icon();
    content: $fa-var-spinner;
    font-size:20px;
    -webkit-animation: apply-cards-spin 2s infinite linear;
    animation: apply-cards-spin 2s infinite linear;
  }
}


@-webkit-keyframes apply-cards-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes apply-cards-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

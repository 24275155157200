//offcanvase menu
.nav-offcanvas.nav-pills.nav-stacked {
  > li > a {
    border-radius:0;
    padding: $nav-link-padding;
    color:#fff;
    font-weight:bold;
     font-size:16px;
  }
  > li.active > a {
    color:$brand-secondary;
  }
  
  > li + li {
    margin-top:0;
  }
  
  /*submenu*/
  > li > ul {
    list-style: none;
    padding-left:0;
    padding-bottom:20px;
    > li {
      //aside nav adds arrow in  :before
      &:before {
        display:none;
      }
    }
    > li > a {
        position: relative;
        display: block;
        padding: $nav-link-padding;
        padding-left:30px;
        color:#fff;
        font-size:16px;
        font-weight:normal;
        &:before {
          font-family: 'icomoon';
          @extend .icon-angle-right;
          position:absolute!important;
          left:16px;
          top:10px!important;
        }
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: $nav-link-hover-bg;
        }
    }


    > li.active > a {
      color:$brand-secondary;
    }
    
  }
}

.nav-main {
  margin-top: 10px;
  > li {
    > a {
      color:$gray;
      border-radius: 0;
      font-size: 16px;
      font-weight:bold;
      margin:0 9px;
      padding:10px 0 0 0;
      border-bottom:1px solid transparent;
      @include isaac-respond-min-width(lg) {
        font-size: 16px;
        margin:0 20px;
        padding:7px 0 0 0;
      }
    }

    &.active {
      > a {
        border-bottom:1px solid $brand-primary;
      }
    }

    &.dropdown.open {
      > a {
        border-color:transparent;
      }
    }

    .icon-home {
      color:$brand-primary;
    }


  }


}

.nav-metamenu {
  display:inline-block;
  clear:both;
  margin-top:3px;
  .nav-pills > li > a {
    color:$brand-primary;
    font-size:16px;
    &:hover, &:active, &:focus {
      color:$brand-primary;
    }
  }
}


.nav-stacked {
  > li {

    a {
      color:$brand-primary;
      padding:6px 0 7px;
      &:hover, &:active, &:focus {
        text-decoration: underline;
        color:$brand-primary;
      }
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        font-weight:bold;
      }
    }
  }
}

aside nav {
  //all levels aside menu
  ul {
    margin-top:-6px;
    @extend .nav;
    @extend .nav-stacked;
  }

  //second level aside menu
  ul > li > ul {
    padding-bottom:10px;
    > li {
      > a {
        padding:0 0 6px 12px;

      }
      &:before {
        font-family: 'icomoon';
        @extend .icon-angle-right;
        position:absolute!important;
        left:0;
        top:0!important;
      }
    }
  }


}


.dropdown-menu {

  -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.2);
  box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.2);
  border:1px solid $brand-spotcolor-dark;
  margin:-2px 0 0 0;
  background-color: $brand-spotcolor-light;

  > li > a {
    border-radius: 3px;
    padding:10px 10px;
    @include isaac-respond-min-width(lg) {
      padding:10px 20px;
    }

    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }
}

/* Hide navigation in apply */
body.apply ul.nav-main {
    display: none;
}


.standout {
  $height-md : 370px;
  $height-lg : 310px;
  background-color: #e3f3f5;
  padding: 20px 0 30px 0;

  @include isaac-respond-min-width(sm) {
    padding: 30px 0 60px 0;
  }
  @include isaac-respond-min-width(md) {
    padding: 65px 0 90px 0;
  }



  //equal height
  .standout-img {
    height:250px;
  }
  .standout-body, .standout-img, .panel-standout {
    @include isaac-respond-min-width(md) {
      height:$height-md;
    }
    @include isaac-respond-min-width(lg) {
      height:$height-lg;
    }
  }



  .standout-body {
    h1, h2, h3, h4, h5, h6 {
      margin-top:0;
      text-align: center;
      @include isaac-respond-min-width(sm) {
        text-align: left;
      }
    }
  }

  .standout-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .panel-standout {
    margin-top:30px;
    @include isaac-respond-min-width(md) {
      margin-top:0;
    }
  }
}
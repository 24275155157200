#accordion .row {
  @include isaac-respond-min-width(sm) {
    .full {
      width: 100%;
    }
    .half {
      width: 50%;
    }
    .quarter {
      width: 25%;
      min-width: 50px;
    }
    .third {
      width: 33.3333%;
    }
    .sixth {
      width: 16.6666%;
    }
  }
}

//seperate date fields and zipcode fields
#accordion .row .fieldDivider {
  color: #999999;
  display: inline-block;
  font-size: 9px;
  height: 24px;
  line-height: 40px;
  margin: 0;
  min-width: 0;
  padding: 0;
  text-align: center;
  width: 5px;
}

.ibanLookup {
  margin-bottom: 10px;
}

#accordion .apply-form .row {
  position: relative;
  .control-column {
    position: relative;
    padding-right: 25px + ($grid-gutter-width / 2); //reserve space for error icon (20px wide)
  }
}

#accordion .row .asterix {
  color: #999999;
}

#accordion .invisible {
  display: none;
}

#accordion .row input .invisible {
  display: none;
}

#accordion .row.dateField {
  .customDateField {
    display: inline-block;

    &.day,
    &.month {
      width: 60px !important;
      margin: 6px 0px 0 0;
    }

    &.year {
      width: 70px !important;
      margin-top: 6px;
    }
  }
}

#accordion .row.postalCodeDiv {
  .postalCodeNumbers {
    display: inline-block;
    margin: 6px 0px 0 0;
    width: 70px !important;
  }

  .postalCodeChars {
    display: inline-block;
    margin-top: 6px;
    width: 50px !important;
  }
}

#accordion .row .checkbox {
  display: block;
  float: left;
  width: 15px;
  border: 0;
}

#accordion .control-label .description div {
  text-align: right;
}
#accordion .radio-inline-sm {
  @include isaac-respond-min-width(sm) {
    display: inline-block;
  }
}

//Bootstrap makes .radio-inline and .checkbox-inline relative
// reset this, since the error icons are positioned absolutely in .col-sm-8 control-column
#accordion .radio,
#accordion .radio-inline,
#accordion .checkbox-inline {
  position: static;
}

#accordion .autocompleteWidget {
  overflow: visible;
  position: relative;
}

//ibiza errors
#ibiza-error-msg {
  .errorList {
    margin: 15px 0 5px 0;
  }
}

.form-group.error {
  .form-control {
    border-color: $state-danger-border !important;
    background: $state-danger-bg !important;
  }
  .control-label .description {
    color: $state-danger-border !important;
  }
}
.error-calc {
  border-color: $state-danger-border !important;
  background: $state-danger-bg !important;
}

//used by postalcode lookup
.loading {
  display: block;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 6px;
  right: 15px;
}

.loadingbar {
  background: url(img/loadergif.gif) no-repeat;
  display: block;
  height: 27px;
  margin: 5px auto;
  width: 80px;
}

#accordion .btn-bar {
  .btn {
    position: relative;
  }

  .btn-bar-prev {
    padding-right: 25px + ($grid-gutter-width / 2); //reserve space for error icon (20px wide)
    @include isaac-respond-min-width(sm) {
      text-align: right;
      padding-right: 0;
    }
    .btn {
      text-align: center;
      display: block;
      width: 100%;
      @include isaac-respond-min-width(sm) {
        display: inline-block;
        width: auto;
      }
    }
  }

  .btn-bar-next {
    padding-right: 25px + ($grid-gutter-width / 2); //reserve space for error icon (20px wide)
    padding-bottom: 35px;
  }

  //loading indicator for submit button
  .loading {
    top: 7px;
    right: -25px;
    color: #000;
  }
}

/* -------- Uitzonderingen --------   */
#accordion .accept {
  margin-left: 5px !important;
  @include clearfix;
}

#accordion .accept input[type='checkbox'] {
  display: block;
  float: left;
}

#accordion .accept label {
  padding-left: 18px;
  display: block;
  width: 95%;
  position: relative;
}

/* -------- Jquery UI aanpassingen -------- */

#accordion .ui-widget-content {
  background: none;
  background-color: #fff;
}

#accordion .ui-autocomplete {
  width: 145px;
  position: absolute;
  left: 0;
  top: 20px;
  margin: 0;
  margin-top: 4px;
  padding: 0;
}

//autocomplete list
#accordion .ui-menu-item {
  width: auto;
  font-weight: normal !important;
  background-color: #fff;
  border: 0;
  color: $text-color;
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    cursor: pointer;
    display: block;
    padding: 5px;
    text-align: left;
    width: auto;
    color: $text-color;
  }

  .ui-state-hover {
    width: auto;
    background: none;
    background-color: #e5e5e5;
    border: 0;
    color: #000;
    font-weight: normal !important;
    text-decoration: none;
  }
}

#accordion .ui-widget-content {
  border: 1px solid $input-border !important;
  border-top: 0 !important;
  z-index: 99 !important;
}

#accordion .ui-autocomplete {
  top: 23px;
}

#accordion .ui-icon {
  position: absolute;
  left: 0.5em;
  top: 50%;
  margin-top: -8px;
}

/*
validation icons
*/
.validicon,
.error.tipsy {
  position: absolute;
  top: 6px;
  right: $grid-gutter-width/2;
}

.error.tipsy {
  display: block;
  position: absolute;
  width: 23px;
  height: 30px;
  text-indent: -200px;
  overflow: hidden;
  &:before {
    position: absolute;
    display: block;
    right: -180px;
  }
}

.control-label {
  position: relative;
  padding-right: 15px;
  display: flex;
  white-space: nowrap;
  flex-direction: row-reverse;

  @include isaac-respond-width(xs) {
    font-size: 13px;
    flex-direction: row;
  }

  .tipsy-tooltip {
    padding-right: 10px;
    @include isaac-respond-width(xs) {
      padding-left: 10px;
    }
    @include isaac-respond-min-width(sm) {
      top: 9px;
    }
  }
}

a .dotEditListBox {
  z-index: 5;
}

.accordioncontent {
  margin-top: 20px;
}

/* popup status aanvraag */
.popupbodyclass {
  background: none !important;
}

#popup h2 {
  margin-top: 0;
}

//iban lookup
.modal-iban-lookup {
  @include isaac-respond-min-width(md) {
    width: 450px;
  }

  .modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.convertIban {
  margin: 20px 0;
  .table {
    margin-bottom: 0;
  }
  @include isaac-respond-max-width(xs) {
    .btn {
      width: 100%;
    }
  }
  .ibanlookup_error {
    color: red;
  }
}

#iban-copy {
  @include isaac-respond-max-width(xs) {
    .btn {
      width: 100%;
    }
  }
}

.apply-wizard-nav {
  border-bottom: 0;
  margin: 0 (-$grid-gutter-width / 2) 30px (-$grid-gutter-width / 2);
  @include isaac-respond-min-width(sm) {
    margin: 0;
  }
  display: flex;
  flex-grow: 1;

  > li {
    margin: 0;
    width: 50px;
    @include isaac-respond-min-width(sm) {
      flex-grow: 1;
    }
    counter-increment: apply-wizard-nav-counter;

    > a {
      cursor: default;
      position: relative;
      color: $apply-wizard-nav-color;
      background-color: $apply-wizard-nav-bg;
      border: 0;
      margin: 0;
      padding: 0;
      height: $apply-wizard-nav-height;
      line-height: $apply-wizard-nav-height;
      text-align: center;
      border-radius: 0;

      &:hover,
      &:focus {
        border: 0;
        background-color: $apply-wizard-nav-bg;
      }

      .apply-wizard-nav-stepid {
        font-weight: bold;
        //add step number
        &:before {
          content: counter(apply-wizard-nav-counter) '.\0000a0';
          display: inline-block;
        }
      }

      .apply-wizard-nav-steplabel {
        display: none;
        @include isaac-respond-min-width(sm) {
          display: inline;
        }
      }
    }
    &.active {
      flex-grow: 1;

      > a {
        padding-right: $apply-wizard-nav-height/2;
        @include isaac-respond-min-width(sm) {
          padding-right: 0;
        }
        &,
        &:hover,
        &:focus {
          background-color: $apply-wizard-nav-active-bg;
          border: 0;
          color: $apply-wizard-nav-active-color;
        }
        //add arrows
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          width: 0;
          height: 0;
          border-width: $apply-wizard-nav-height/2 0 $apply-wizard-nav-height/2
            $apply-wizard-nav-height/2;
          border-style: solid;
          border-color: $apply-wizard-nav-active-bg $apply-wizard-nav-bg
            $apply-wizard-nav-active-bg $apply-wizard-nav-completed-bg;
          left: -10px;
        }

        .apply-wizard-nav-steplabel {
          display: inline;
        }
      }
    }

    &.active + li {
      > a {
        padding-left: $apply-wizard-nav-height/2;
        @include isaac-respond-min-width(sm) {
          padding-left: 0;
        }
        &:before {
          left: -10px;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          width: 0;
          height: 0;
          border-width: $apply-wizard-nav-height/2 0 $apply-wizard-nav-height/2
            $apply-wizard-nav-height/2;
          border-style: solid;
          border-color: $apply-wizard-nav-bg $apply-wizard-nav-active-bg
            $apply-wizard-nav-bg $apply-wizard-nav-active-bg;
        }
      }
    }

    &.completed {
      > a {
        border: 0;
        color: $apply-wizard-nav-completed-color;
        background-color: $apply-wizard-nav-completed-bg;
        cursor: pointer;
        &:hover,
        &:focus {
          border: 0;
          color: $apply-wizard-nav-completed-color;
        }

        @include isaac-respond-min-width(sm) {
          //add icon indicating completed step
          &:after {
            @include fa-icon();
            content: $fa-var-check;
            margin-left: 4px;
            color: $brand-success;
          }
        }
      }
    }

    &:first-child {
      @include isaac-respond-min-width(sm) {
        a {
          border-top-left-radius: $apply-wizard-nav-border-radius;
          border-bottom-left-radius: $apply-wizard-nav-border-radius;
        }
      }

      //no left arrow on first step
      > a:before {
        display: none;
      }

      &.active a {
        padding-left: 0;
      }
    }

    &:last-child {
      &.active a {
        padding-right: 0;
      }

      @include isaac-respond-min-width(sm) {
        a {
          border-top-right-radius: $apply-wizard-nav-border-radius;
          border-bottom-right-radius: $apply-wizard-nav-border-radius;
        }
      }
    }
  }
}

#accordion {
  .alert-info {
    margin-top: 30px;
    margin-bottom: 0;
  }
}

//apply-calculator-aflossing-options are added by the calculator framework js, and do not have standard bootstrap styling
.apply-calculator-aflossing-options {
  padding: 0;
  position: relative;
  input {
    margin: 11px 0 0 0;
    position: absolute;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 7px 0 0 20px;
    margin: 0 10px 0 0;
    vertical-align: middle;
    cursor: pointer;
    & small {
      display: none;
    }
  }
}

.modal-mobile {
  .modal-dialog {
    position: absolute;
    top: 50% !important;
    min-width: 80%;
    left: 50%;
    transform: translate(-53%, -50%) !important;
    .modal-body {
      font-size: 15px;
    }
  }
  h4 {
    text-align: left;
    margin: 0;
  }
}

/* radio buttons & dropdowns redesign IQAN-2507 */
#accordion .radio-inline,
#accordion .radio-inline-sm,
.calculator-options .radio-inline {
  border: 1px solid #cccccc;
  background-color: #fff;
  border-bottom-width: 2px;
  padding: 10px;
  min-width: 80px;
  max-width: 120px;
  text-align: center;
  margin: 5px 5px 0 0;
  position: relative;
  @include isaac-respond-width(xs) {
    width: 47%;
    max-width: 100% !important;
    &.selected-radio {
      position: relative;
      &:after {
        position: absolute;
        top: -4px;
        right: -13px;
        content: '';
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #3bb54a;
        transform: rotate(45deg);
      }
      &:before {
        position: absolute;
        top: 0;
        right: 1px;
        font-family: FontAwesome;
        content: '\f00c';
        color: #fff;
        z-index: 1;
        font-size: 12px;
      }
    }
    & + .validicon {
      display: none;
    }
  }
  input {
    width: 0;
    overflow: hidden;
    opacity: 0;
  }
  span {
    max-width: 94%;
    word-break: break-word;
  }
  &.selected-radio {
    border-color: #3bb54a;
    color: #3bb54a;
  }
  &.four-options {
    min-width: 47%;
    max-width: 47%;
    float: left;
    min-height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // &:hover {
  //   transform: scale(0.98);
  // }
  &.focus-label {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
}

.autocompleteWidget {
  .suggesting-input {
    position: absolute;
    top: 0;
    left: 1px;
    color: #ccc;
    z-index: 0 !important;
    border: 0;
  }
  .form-control {
    background: transparent !important;
    position: absolute;
    z-index: 1;
  }
  ul {
    visibility: hidden !important;
  }
}

.accept {
  min-height: 25px;
  @include isaac-respond-width(xs) {
    min-height: 34px;
  }
  input {
    opacity: 0;
  }
  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    &:after {
      cursor: pointer;
      content: '';
      background-color: #fff;
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      padding: 8px;
      position: absolute;
      top: 2px;
      display: block;
    }
  }
  p {
    position: absolute;
    left: 30px;
  }
  input:checked + span:after {
    background-color: #3bb54a;
    border: 1px solid #3bb54a;
  }
  input:checked + span:before {
    position: absolute;
    top: 4px;
    left: 4px;
    font-family: FontAwesome;
    content: '\f00c';
    color: #fff;
    z-index: 1;
    font-size: 12px;
  }
  &.marketing {
    height: 70px;
    @include isaac-respond-width(xs) {
      height: 136px;
    }
  }
}
#BorrowerSurnamePrefixDiv,
#AddressHousenumberSupplementDiv,
#BorrowerNationalityDiv,
#PartnerSurnamePrefixDiv,
#PreviousAddressHousenumberSupplementDiv {
  @include isaac-respond-max-width(sm) {
    margin-bottom: 45px;
  }
}

#SpendingPurposeDiv {
  .validicon {
    display: none !important;
  }
}

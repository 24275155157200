.tipsy:not(.error) {
  margin-top:-3px;
  margin-left:8px;
}

.tipsy-inner, .errortip-inner {
  padding: 10px 15px 15px;
  background-color: $apply-tipsy-bg;
  color: $apply-tipsy-color;
  max-width: 250px;
  text-align: left;
  font-size:$font-size-base;
  z-index:$zindex-dropdown - 2;
}
.tipsy-west, .errortip-west {
  &:before {
    position:absolute;
    left:-6px;
    top:50%;
    margin-top:-3px;
    @include caret(6px, $apply-tipsy-bg, left);
  }
}

.tipsy-east, .errortip-east {
  &.tipsy:not(.error) {
    margin:41px 0 0 24px;
  }

  &:before {
    position:absolute;
    right:6px;
    top:-6px;
    @include caret(6px, $apply-tipsy-bg, top);
  }
}


.errortip-inner {
  color: red !important;
}

.advicequestions {

  #radioForm .question {
    padding:10px 0;
    > div > label {
      @include clearfix;
      display:block;

      input {
        float:left;
        display:block;
      }

      span {
        display:block;
        float:left;
        width:calc(100% - 20px);
        padding-left:15px;
        cursor:pointer;
      }

    }
  }

  .questionTitel {
    padding: 10px 0 15px 0;
    border-bottom: 1px solid $brand-spotcolor-dark;
    .stepNumber {
      font-weight:bold;
      margin-right:8px;
    }
  }

  .btn-next {
    cursor:pointer;
    display:block;
    margin: 5px 0 30px 0;
    &.disabled {
      opacity: 0.5;
    }
  }

}

.adviceresult {
  display:none;
}
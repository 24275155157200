#applicationForm {
    margin-top: 30px;
}

#accordion {
    h2 {
        margin-top: 22px;
    }

    //forms in apply are styled different from the Bootstrap default (as used in contact form)
    .control-label {
        font-weight: normal;
        font-size: inherit;
        color: inherit;
        text-align: right;
    }

    .form-control {
        height: 36px;
        background-color: #fff;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        border: 1px solid #ccc;

        &[readonly],
        &[disabled] {
            background-color: #eee;
        }
    }

    //apply has standard btn-lg, burt they appear too large for Primeline
    .btn-lg {
        padding: 11px 24px;
        line-height: 1.3em;
        font-size: 17px;
    }

    .btn-bar {
        small {
            padding-top: 15px;
            display: inline-block;
        }
    }
    .btn-bar-next {
        .btn {
            @include isaac-respond-width(xs) {
                font-size: 13px;
            }
            @include isaac-respond-width(sm) {
                font-size: 11px;
            }
            @include isaac-respond-width(md) {
                font-size: 13px;
            }
        }
    }
}

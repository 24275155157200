//.tooltip is used on the calculators
// .tooltip also uses .tipsy. These classes are loaded from _apply-cards-icons.scss and -apply-cards-tooltip.scss
.tooltip {
  margin:0;
  padding:0;
  cursor:default;
  text-decoration: none!important; //for IE
  &:after {
    display:inline-block;
    border:1px solid $brand-primary;
    color:$brand-primary;
    text-align: center;
    line-height:21px;
    width:21px;
    height:21px;
    border-radius:50%;
    font-size:15px;
    font-weight:bold;
    line-height:1;
    padding-top:2px;
    content: "i";
  }
}

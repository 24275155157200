.alert-info {
  background-color: #e2f4f8;
  color:inherit;
}

.alert-info-icon {
  &:before {
    display:inline-block;
    position: relative;
    color:$brand-secondary;
    top:3px;
    font-family: 'icomoon';
    speak: none;
    font-size:21px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e615";

  }
}
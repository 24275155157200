@font-face {
  font-family: 'Open Sans';
  src: url('/global/fonts/opensans/OpenSans-Regular-webfont.eot');
  src: url('/global/fonts/opensans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.woff2') format('woff2'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.woff') format('woff'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.ttf') format('truetype'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  src: url('/global/fonts/opensans/OpenSans-Semibold.eot');
  src: url('/global/fonts/opensans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('/global/fonts/opensans/OpenSans-Semibold.woff') format('woff'),
  url('/global/fonts/opensans/OpenSans-Semibold.ttf') format('truetype'),
  url('/global/fonts/opensans/OpenSans-Semibold.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-weight: bold;
  src: url('/global/fonts/opensans/OpenSans-800.eot');
  src: url('/global/fonts/opensans/OpenSans-800.eot?#iefix') format('embedded-opentype'),
  url('/global/fonts/opensans/OpenSans-800.woff') format('woff'),
  url('/global/fonts/opensans/OpenSans-800.ttf') format('truetype'),
  url('/global/fonts/opensans/OpenSans-800.svg#OpenSans') format('svg');
}


//add subtle transition on elements that have a hovered or active state
a {
  transition:
    border 0.2s ease-in-out 0s,
    text-decoration 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out 0s
  ;
}
.btn, .form-control {
  transition: color 0.2s ease-in-out 0s,
    background 0.2s ease-in-out 0s,
    border 0.2s ease-in-out 0s,
    text-decoration 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out 0s
  ;
}


.text-underline {
  text-decoration: underline;
}

.link-more {
  font-weight: bold;
  position:relative;
  padding-left:14px;

  &:before {
    @include caret($caret-width-base, $brand-primary, right);
    top:7px;
    left:0;
  }

}

.header-border-bottom {
  border-bottom:1px solid $gray-lighter;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom:22px;
}

h1.header-no-top-margin,
h2.header-no-top-margin,
h3.header-no-top-margin,
h4.header-no-top-margin,
h5.header-no-top-margin,
h6.header-no-top-margin {
  margin-top:0;
}

aside:not(.offcanvas-menu) {
  margin-top: 0px;
  @include isaac-respond-min-width(sm) {
    margin-top: 22px;
  }

}

.content-main {
  padding-top: 22px;
}

//default styling for content that was added by the Dotcms WYSIWYG editor
.content-wysiwyg {
  h3 {
    margin-top:40px;
    margin-bottom:8px;
  }

  ul {
    padding-left: 0;
    list-style: none;
    > li {
      position:relative;
      padding-left: 16px;
      padding-bottom:8px;
      &:before {
        font-family: 'icomoon';
        content: "\e60c"; //angle-right
        padding-right:10px;
        color:$brand-primary;
        position: absolute;
        top:1px;
        left:0;
      }
    }
  }
}

.content-wysiwyg ul.list-check,
.list-check {
  @include list-unstyled;
  > li {
    padding-left:30px;
    padding-bottom:8px;
    &:before {
      font-family: 'icomoon';
      font-size:20px;
      display:block;
      position:absolute;
      @extend .icon-check;
      color:#00c70e;
      margin-left:-30px;
      margin-top:-4px;
      left: inherit;
    }
  }
}

.list-cross {
  @include list-unstyled;
  > li {
    padding-left:30px;
    padding-bottom:8px;
    &:before {
      font-family: 'icomoon';
      font-size:20px;
      display:block;
      position:absolute;
      @extend .icon-cross;
      color:#f26722;
      margin-left:-30px;
      margin-top:-4px;
    }
  }
}
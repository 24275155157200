.page-header {
  background-color: $brand-primary;
  margin:0 0 20px 0;
  padding:54px 0;
  @include isaac-respond-min-width(md) {
    padding:62px 0;
  }
  h1 {
    color:#fff;
    margin:0;
    font-size:30px;

    @include isaac-respond-min-width(md) {
      font-size:48px;

    }

  }
}